<template>
  <div class="all">
    <Nav class="nav" :front="true"></Nav>
    <div class="desktop-present">
      <template v-for="(content, index) in contents">
        <!-- 轮播 -->
        <div :key="content.type + '_' + index" v-if="content.type == 'TOP_SHOW'" class="whole front-show" @mouseenter="stopTimer(index)" @mouseleave="restartTimer(index)">
          <div class="pic-container">
            <div
              v-for="(pic, contentIndex) in content.list"
              :key="pic + 'pic_' + contentIndex"
              class="front-pic"
              :class="{ active: content.picIndex == contentIndex }"
              @click="onfrontPost(content.picIndex)"
            >
              <div class="pic-inner" :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"></div>
            </div>
          </div>
          <div class="nav-container">
            <div
              v-for="(pic, navIndex) in content.list"
              :key="pic.id + '_nav' + navIndex"
              class="nav-pic"
              :class="{ active: content.picIndex == navIndex }"
              @click="scrollTo(index, navIndex)"
            ></div>
          </div>
          <!-- 业内焦点 -->
          <div class="fixtopic-right">
            <div class="fixtopic-top">
              <div class="title" @click="onClickTopic(jiaodianObj.id)">{{ jiaodianObj.title }}</div>
              <div class="content">
                <div class="item" v-for="k in jiaodianObj.list" :key="'jiaobian_' + k.postId" @click="onLookPost(k.postId)">
                  {{ k.title }}
                </div>
              </div>
            </div>
            <div class="fixtopic-bottom">
              <div class="title" @click="onClickTopic(xuehuiactivityObj.id)">{{ xuehuiactivityObj.title }}</div>
              <div class="content">
                <div class="item" v-for="k in xuehuiactivityObj.list" :key="'xuehuia_' + k.postId">
                  {{ k.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 下面的所有的FT -->
      <template>
        <div class="fixtopic-ft">
          <!-- 左边的FT -->
          <div class="ft-left">
            <!-- 第一部分 -->
            <div class="ft-onecontent">
              <div class="ft-one">
                <!-- 年度评选 -->
                <div class="title" @click="onClickTopic(yearSelectObj.id)">{{ yearSelectObj.title }}</div>
                <div class="con">
                  <div class="item" v-for="k in yearSelectObj.list" :key="'year_' + k.postId" @click="onLookPost(k.postId)">{{ k.title }}</div>
                </div>
              </div>
              <div class="ft-two">
                <!-- 征稿启事 -->
                <div class="title" @click="onClickTopic(contributionsObj.id)">{{ contributionsObj.title }}</div>
                <div class="con">
                  <div class="item" v-for="k in contributionsObj.list" :key="'contribution_' + k.postId" @click="onLookPost(k.postId)">{{ k.title }}</div>
                </div>
              </div>
              <div class="ft-three">
                <div class="title" @click="onUserShow()">{{ userShowObj.title }}</div>
                <div class="con">
                  <div class="item" v-for="k in userShowObj.list" :key="'userShow_' + k.id" @click="onDetailUser(k.id)">
                    <div class="us-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }"></div>
                    <div class="us-name">{{ k.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 版面 -->
            <div class="ft-page">
              <div class="title" @click="onClickTopic(excellentObj.id)">{{ excellentObj.title }}</div>
              <div class="content">
                <div class="item" v-for="k in excellentObj.list" :key="'page_' + k.postId" @click="onLookPost(k.postId)">
                  <div
                    class="img"
                    :style="{
                      backgroundImage: `url(` + k.postImgURL + `)`,
                    }"
                  ></div>
                  <div class="text">{{ k.title }}</div>
                </div>
              </div>
            </div>
            <!-- 下面的12 -->
            <div class="ft-prop">
              <div class="ft-ac">
                <div class="title" @click="onClickTopic(ftacObj.id)">{{ ftacObj.title }}</div>
                <div class="con" v-if="ftacObj.list[0]" @click="onLookPost(ftacObj.list[0].postId)">
                  <div
                    class="ac-img"
                    :style="{
                      backgroundImage: `url(` + ftacObj.list[0].postImgURL + `)`,
                    }"
                  ></div>
                  <div class="text">{{ ftacObj.list[0].title }}</div>
                </div>
              </div>
              <div class="ft-report">
                <div class="title" @click="onClickTopic(ftreportObj.id)">{{ ftreportObj.title }}</div>
                <div class="content">
                  <div class="item" v-for="k in ftreportObj.list" :key="'report_' + k.postId" @click="onLookPost(k.postId)">
                    <div
                      class="img"
                      :style="{
                        backgroundImage: `url(` + k.postImgURL + `)`,
                      }"
                    ></div>
                    <div class="text">{{ k.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边的FT -->
          <div class="ft-right">
            <div class="ft-one">
              <div class="title" @click="onClickTopic(huikanObj.id)">{{ huikanObj.title }}</div>
              <div class="content" v-if="huikanObj.list[0]" @click="onLookPost(huikanObj.list[0].postId)">
                <div
                  class="img"
                  :style="{
                    backgroundImage: `url(` + huikanObj.list[0].postImgURL + `)`,
                  }"
                ></div>
                <div class="text">{{ huikanObj.list[0].title }}</div>
              </div>
            </div>
            <div class="ft-two">
              <div class="title" @click="onClickTopic(shortVideoObj.id)">{{ shortVideoObj.title }}</div>
              <!-- <div class="con" v-if="shortVideoObj.list[0].posts[0]">
                <div
                  class="ft-img"
                  :style="{
                    backgroundImage: `url(` + shortVideoObj.list[0].posts[0].postImgURL + `)`,
                  }"
                ></div>
              </div> -->
              <div class="video-con">
                <div class="video-item" v-for="video in shortVideoObj.list" :key="'video_' + video.postId + '_'" @click="onLookPost(video.postId)">
                  <video
                    :src="video.videoURL"
                    :poster="video.posterURL"
                    :ref="'video_' + '_' + video.postId"
                    @mouseenter="onVideoEnter('video_' + '_' + video.postId)"
                    @mouseleave="onVideoLeave('video_' + '_' + video.postId)"
                    loop
                    :muted="video.mute"
                    height="270"
                  ></video>
                  <i
                    class="mute el-icon-headset active"
                    v-if="video.mute"
                    @mouseenter="onVideoEnter('video_' + '_' + video.postId)"
                    @mouseleave="onVideoLeave('video_' + '_' + video.postId)"
                    @click.stop="video.mute = !video.mute"
                  ></i>
                  <i
                    class="mute el-icon-headset"
                    v-if="!video.mute"
                    @mouseenter="onVideoEnter('video_' + '_' + video.postId)"
                    @mouseleave="onVideoLeave('video_' + '_' + video.postId)"
                    @click.stop="video.mute = !video.mute"
                  ></i>
                  <div class="video-text">
                    {{ video.title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ft-three">
              <div class="title" @click="onClickTopic(jiazuoObj.id)">{{ jiazuoObj.title }}</div>
              <div class="content" v-if="jiazuoObj.list[0]" @click="onLookPost(jiazuoObj.list[0].postId)">
                <div
                  class="img"
                  :style="{
                    backgroundImage: `url(` + jiazuoObj.list[0].postImgURL + `)`,
                  }"
                ></div>
                <div class="text">{{ jiazuoObj.list[0].title }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-for="(content, index) in contents">
        <!-- 友情链接 -->
        <div class="link" :key="content.type + '_' + index" v-if="content.type == 'BOTTOM_SHOW'">
          <div class="link-title">
            <div class="link-name">友情链接</div>
            <div class="clip"></div>
          </div>
          <div class="link-con">
            <div class="link-list">
              <div
                class="link-detail"
                v-for="p in content.list"
                :key="'link-detail' + p.id + '_' + index"
                v-bind:class="{
                  transformLeft: content.isleft,
                }"
              >
                <a :href="p.link" target="_blank">{{ p.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="mobile-present">
      <template v-for="(content, index) in contents">
        <!-- 轮播 -->
        <div :key="content.type + '_' + index" v-if="content.type == 'TOP_SHOW'" class="whole front-show" @mouseenter="stopTimer(index)" @mouseleave="restartTimer(index)">
          <div class="pic-container">
            <div
              v-for="(pic, contentIndex) in content.list"
              :key="pic + 'pic_' + contentIndex"
              class="front-pic"
              :class="{ active: content.picIndex == contentIndex }"
              @click="onfrontPost(content.picIndex)"
            >
              <!-- <div class="pic-inner-left">
              <div class="inner" :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"></div>
            </div>
            <div class="pic-inner-right">
              <div class="inner" :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"></div>
            </div> -->
              <div class="pic-inner" :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"></div>
            </div>
          </div>
          <div class="nav-container">
            <div
              v-for="(pic, navIndex) in content.list"
              :key="pic.id + '_nav' + navIndex"
              class="nav-pic"
              :class="{ active: content.picIndex == navIndex }"
              @click="scrollTo(index, navIndex)"
            ></div>
          </div>
        </div>

        <!-- 广告 -->
        <div class="ad" :key="content.type + '_' + index" v-if="content.type == 'AD'">
          <div class="ad-con">
            <div class="ad-list">
              <a :href="content.url" target="_blank">
                <img class="ad-detail" :src="content.imgurl" />
              </a>
            </div>
          </div>
        </div>
        <!-- 友情链接 -->
        <div class="link" :key="content.type + '_' + index" v-if="content.type == 'BOTTOM_SHOW'">
          <div class="link-title">
            <div class="link-name">友情链接</div>
            <div class="clip"></div>
          </div>
          <div class="link-con">
            <div class="link-list">
              <div
                class="link-detail"
                v-for="p in content.list"
                :key="'link-detail' + p.id + '_' + index"
                v-bind:class="{
                  transformLeft: content.isleft,
                }"
              >
                <a :href="p.link" target="_blank">{{ p.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <Bottom></Bottom>
  </div>
</template>
<script>
var timers = []
var ustimers = []
var localTimer = []
import RD from '@/api/RD'
import Nav from '@/components/memberNav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  name: 'Index',
  components: { Nav, Bottom },
  data() {
    return {
      logined: false,
      search: '',
      clickIndex: -1,
      showBg: false, // 公告背景图的显示与隐藏
      bgImg: '', // 公告背景图片
      AddrImgURL: '',
      isTransform: false, // 点击移动一个
      isTransformRight: false,
      pinpaiInfo: {}, // 品牌的信息
      dongtaiInfo: {}, // 视觉动态
      gonggaoImg: '', // 公告的图片显示
      gonggaoNoImg: '', // 公告没移上去的时候的照片
      ATFourList: [], // 影赛前四个
      ATLastList: [], // 影赛后四个
      picIndex: 0,
      skyIndex: 0, // 空中安徽
      contents: [], // main里面的所有数据
      skyList: [], // 空中安徽前三个
      localPostList: [],
      lcIndex: 0,
      localPostLoading: false,
      ustransformDistance: 0, //摄影师每次移动的距离
      shortVideoList: [], // 短视频的后3个
      gonggaoList: [], // 公告的列表
      tukuList: [], // 图库的列表
      jiaodianObj: {}, // 业内焦点
      xuehuiactivityObj: {}, // 学会活动
      yearSelectObj: {}, // 年度评选
      contributionsObj: {}, // 征稿启事
      userShowObj: {}, // 融媒人物
      excellentObj: {},
      ftacObj: {},
      ftreportObj: {},
      huikanObj: {},
      shortVideoObj: {},
      jiazuoObj: {},
    }
  },
  created() {
    this.reload()
  },
  mounted() {
    window.addEventListener('scroll', this.gotoScroll)
  },
  methods: {
    reload() {
      var thiz = this
      RD.pure()
        .user()
        .sync()
        .then(() => {
          this.logined = true
        })
      RD.addr()
        .id(10)
        .downAddress()
        .then((data) => {
          thiz.tukuList = data
        })
      RD.notice()
        .allByGroup(1)
        .then((type) => {
          thiz.gonggaoList = type
        })
      RD.index()
        .main(2)
        .then((data) => {
          data.contents.forEach((content, contentIndex) => {
            content.picIndex = 0
            content.skyIndex = 0
            content.localIndex = 0
            content.isTransform = false
            content.isTransformRight = false
            content.isUp = false
            content.isleft = false
            switch (content.type) {
              case 'TOP_SHOW':
                content.list.forEach((i) => {
                  if (i.frontPicId) {
                    i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
                  }
                })
                timers[contentIndex] = setInterval(() => {
                  this.next(contentIndex)
                }, 3000)
                break
              case 'FTS':
                content.list.forEach((i) => {
                  i.imgURL = RD.fixedTopic().id(i.id).headPic_URL()
                  i.posts.forEach((k) => {
                    k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
                    k.videoimgURL = RD.video().id(k.postId).poster_URL()
                    k.videoURL = RD.video().id(k.postId).svideo_URL()
                    k.posterShow = true
                  })
                })

                switch (content.model) {
                  case 'TITLE_ONLY':
                    content.modelOne = false
                    content.modelTwo = false
                    content.modelThree = false
                    content.modelFour = false
                    content.modelFive = false
                    break
                  case '111':
                    content.modelOne = true
                    content.modelTwo = false
                    content.modelThree = false
                    content.modelFour = false
                    content.modelFive = false
                    break
                  case '222':
                    content.modelTwo = true
                    content.modelOne = false
                    content.modelThree = false
                    content.modelFour = false
                    content.modelFive = false
                    break
                  case '22':
                    content.modelThree = false
                    content.modelOne = false
                    content.modelThree = true
                    content.modelFour = false
                    content.modelFive = false
                    break
                  case '13':
                    content.modelOne = false
                    content.modelTwo = false
                    content.modelThree = false
                    content.modelFour = true
                    content.modelFive = false
                    content.modelSix = false
                    // thiz.$set(content.list[0], 'posts', content.list[0].posts.slice(0, 1))
                    // thiz.$set(content.list[1], 'posts', content.list[1].posts.slice(1, 4))
                    content.list[1].posts.forEach((k, videoIndex) => {
                      if (videoIndex == 1 || videoIndex == 2 || videoIndex == 3) {
                        thiz.shortVideoList.push(k)
                      }
                    })
                    break
                  case '12':
                    content.modelOne = false
                    content.modelTwo = false
                    content.modelThree = false
                    content.modelFour = false
                    content.modelFive = true
                    break
                  case '1111':
                    content.modelOne = false
                    content.modelTwo = false
                    content.modelThree = false
                    content.modelFour = false
                    content.modelFive = false
                    content.modelSix = true
                    break
                }
                timers[contentIndex] = setInterval(() => {
                  this.next(contentIndex)
                }, 3000)
                break
              case 'PFT':
                content.list.forEach((i) => {
                  if (i.frontPicId) {
                    i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
                  }
                })
                timers[contentIndex] = setInterval(() => {
                  this.next(contentIndex)
                }, 3000)

                break
              case 'AD':
                content.imgurl = RD.ad().id(content.id).image_URL()
                break
              case 'AT':
                content.list.forEach((i) => {
                  i.imgURL = RD.activityTopic().id(i.id).headPic_URL(0)
                  // if (index == 1 || index == 0 || index == 3 || index == 2) {
                  //   thiz.ATFourList.push(i);
                  // }
                  // if (index == 4 || index == 5 || index == 6 || index == 7) {
                  //   thiz.ATLastList.push(i);
                  // }
                })
                break
              case 'ANNOUNCE':
                content.list.forEach((k) => {
                  if (k.title == '品牌服务') {
                    thiz.pinpaiInfo = k
                  } else if (k.title == '视觉动态') {
                    thiz.dongtaiInfo = k
                    thiz.gonggaoNoImg = RD.notice().id(thiz.dongtaiInfo.list[0].id).noticeImage_URL()
                  }
                })
                break
              case 'BOTTOM_SHOW':
                // timers[contentIndex] = setInterval(() => {
                //   (index => {
                //     thiz.linkMove(index);
                //   })(contentIndex);
                // }, 3000);
                content.list.forEach((i) => {
                  if (i.id) {
                    i.imgURL = RD.exchange(i.id).headPic_URL()
                  }
                })
                break
              case 'LC':
                content.list.forEach((k) => {
                  k.forEach((i) => {
                    if (k.length === 1) {
                      i.AddrImgURL = RD.addr().id(i.id).bgImage_URL()
                    } else {
                      i.AddrImgURL = RD.addr().id(i.id).bgImage_URL()
                    }
                  })
                  localTimer[contentIndex] = setInterval(() => {
                    this.nextLocal(contentIndex)
                  }, 5000)
                })
                break
              case 'US':
                // userShowObj
                if (content.title == '融媒人物') {
                  thiz.userShowObj = content
                }
                content.list.forEach((i) => {
                  if (i.id) {
                    i.imgURL = RD.userShow().id(i.id).headPic_URL()
                  }
                })
                ustimers[contentIndex] = setInterval(() => {
                  ;((index) => {
                    thiz.move(index)
                  })(contentIndex)
                }, 3000)
                break
              case 'FT':
                content.list.forEach((i, index) => {
                  if (i.postId) {
                    i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
                  }
                })
                if (content.model == '3s') {
                  content.modelOne = true
                  i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()

                  if (index == 1 || index == 0 || index == 2) {
                    thiz.skyList.push(i)
                  }
                } else if (content.model == '111') {
                  content.modelTwo = true
                } else if (content.model == '12') {
                  content.modelThree = true
                } else if (content.model == '21') {
                  content.modelFour = true
                }
                if (content.title == '业内焦点') {
                  thiz.jiaodianObj = content
                } else if (content.title == '学会活动') {
                  thiz.xuehuiactivityObj = content
                } else if (content.title == '年度评选') {
                  thiz.yearSelectObj = content
                } else if (content.title == '征稿启事') {
                  thiz.contributionsObj = content
                } else if (content.title == '优秀版面') {
                  content.list.forEach((k) => {
                    k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
                  })
                  thiz.excellentObj = content
                } else if (content.title == '“美丽中国”全国融媒焦点系列活动') {
                  content.list.forEach((k) => {
                    k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
                  })
                  thiz.ftacObj = content
                } else if (content.title == '媒体报道') {
                  content.list.forEach((k) => {
                    k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
                  })
                  thiz.ftreportObj = content
                } else if (content.title == '学会会刊') {
                  content.list.forEach((k) => {
                    k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
                  })
                  thiz.huikanObj = content
                } else if (content.title == '佳作欣赏') {
                  content.list.forEach((k) => {
                    k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
                  })
                  thiz.jiazuoObj = content
                }

                if (timers[contentIndex]) {
                  clearInterval(timers[contentIndex])
                }
                timers[contentIndex] = setInterval(() => {
                  this.nextsky(contentIndex)
                }, 3000)

                break
              case 'FTV':
                content.list.forEach((i) => {
                  if (i.id) {
                    i.videoimgURL = RD.video().id(i.postId).poster_URL()
                  }
                  i.posterURL = RD.video().id(i.postId).poster_URL()
                  i.videoURL = RD.video().id(i.postId).svideo_URL()
                  i.mute = true
                })
                if (content.title == '短视频') {
                  content.list.forEach((p) => {
                    p.posterURL = RD.video().id(p.postId).poster_URL()
                    p.videoURL = RD.video().id(p.postId).svideo_URL()
                    p.mute = true
                  })
                  thiz.shortVideoObj = content
                }
                break
              case 'NP':
                var query = {}
                var page = 1
                query.storeIds = [1]
                var nowTime = new Date()
                query.uploadTo = nowTime.getFullYear() + '-' + ('' + (nowTime.getMonth() + 1)).padStart(1, '0') + '-' + ('' + nowTime.getDate()).padStart(1, '0')
                var after = new Date(nowTime - 100 * 24 * 3600 * 1000)
                query.uploadFrom = ''
                RD.pure()
                  .query()
                  .query(query, page, 20)
                  .then((items) => {
                    items.list.forEach((i) => {
                      if (i.frontPicId) {
                        i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
                      }
                    })
                    this.$set(content, 'npList', items.list)
                    this.$set(content, 'npIndex', 0)
                    this.npListProcess(content, 0)
                  })
                break
            }
          })
          thiz.contents = data.contents
        })
    },
    move(contentIndex) {
      var thiz = this
      thiz.contents[contentIndex].isUp = true
      setTimeout(() => {
        this.$nextTick(function () {
          this.contents[contentIndex].isUp = false
          var temp = this.contents[contentIndex].list.shift()
          this.contents[contentIndex].list.push(temp)
        })
      }, 2500)
    },
    next(contentIndex) {
      var thiz = this
      thiz.contents[contentIndex].picIndex++
      if (thiz.contents[contentIndex].picIndex >= thiz.contents[contentIndex].list.length) {
        thiz.contents[contentIndex].picIndex = 0
      }
    },
    nextsky(contentIndex) {
      var thiz = this
      thiz.contents[contentIndex].skyIndex++
      if (thiz.contents[contentIndex].skyIndex == 3) {
        thiz.contents[contentIndex].skyIndex = 0
      }
    },
    nextLocal() {
      var thiz = this
      thiz.lcIndex++
      if (thiz.lcIndex == thiz.tukuList.length) {
        thiz.lcIndex = 0
      }
    },
    scrollTo(contentIndex, navIndex) {
      this.contents[contentIndex].picIndex = navIndex
    },
    scrollToSky(contentIndex, navIndex) {
      this.contents[contentIndex].skyIndex = navIndex
    },
    stopTimer(contentIndex) {
      clearInterval(timers[contentIndex])
    },
    restartTimer(contentIndex) {
      if (timers[contentIndex]) {
        clearInterval(timers[contentIndex])
      }
      timers[contentIndex] = setInterval(() => {
        this.next(contentIndex)
      }, 2000)
    },
    usstopTimer(contentIndex) {
      clearInterval(ustimers[contentIndex])
    },
    usrestartTimer(contentIndex) {
      if (ustimers[contentIndex]) {
        clearInterval(ustimers[contentIndex])
      }
      ustimers[contentIndex] = setInterval(() => {
        ;((index) => {
          this.move(index)
        })(contentIndex)
      }, 3000)
    },
    localStopTimer(contentIndex) {
      clearInterval(localTimer[contentIndex])
    },
    localStartTimer(contentIndex) {
      if (localTimer[contentIndex]) {
        clearInterval(localTimer[contentIndex])
      }
      localTimer[contentIndex] = setInterval(() => {
        this.nextLocal(contentIndex)
      }, 5000)
    },
    onClickFT(ftId) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: ftId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 鼠标移入
    onNoposter(contentIndex, videoIndex) {
      this.contents[contentIndex].list[1].posts[videoIndex].posterShow = false
    },
    onMaterialposter(contentIndex, videoIndex) {
      this.contents[contentIndex].list[2].posts[videoIndex].posterShow = false
    },
    // 播放状态
    onVideoStatusChange(currentplay, event) {
      var thiz = this
      console.log('event', event)
      // console.log("ref", thiz.$refs);
      for (var key in thiz.$refs) {
        if (key.substr(0, 6) == 'video_') {
          if (currentplay == key) {
            // 当前正在播放的
            console.log('currentplay')
          } else {
            // 其他的都暂停
            console.log('sibliingpause', thiz.$refs, thiz.$refs[key])
            thiz.$refs[key][0].pause()
          }
        }
      }
    },
    // 空中安徽的稿件点击
    onSkyDetail(skyIndex) {
      var postId = this.skyList[skyIndex].postId
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    onPostDetail(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 城市图库的点击
    onClickLocal(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 轮播的的点击
    onfrontPost(contentIndex) {
      var postid = this.contents[0].list[contentIndex].postId
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postid },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 新闻，图库等栏目
    onClickTopic(topicId) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: topicId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 更多城市信息
    onMoreLocal() {
      let routerUrl = this.$router.resolve({
        path: '/local.html',
      })
      window.open(routerUrl.href, '_blank')
    },
    // 点击城市
    onLocal(content, index) {
      var thiz = this
      console.log('66')
      thiz.lcIndex = index
      thiz.onMoreLocal()
    },
    // localPost(content, localIndex) {
    //   console.log('log', this.contents[content]);
    //   if (this.contents[content].list[0]) {
    //     var thiz = this
    //     var query = {}
    //     var page = 1
    //     query.addrId = this.contents[content].list[0][localIndex].id
    //     query.storeIds = [1]
    //     this.localPostLoading = true
    //     RD.pure()
    //       .query()
    //       .query(query, page, 10)
    //       .then((items) => {
    //         var tempPostList = []
    //         items.list.forEach((k, index) => {
    //           k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
    //           if (index == 1 || index == 0 || index == 2 || index == 3 || index == 4 || index == 5 || index == 6) {
    //             tempPostList.push(k)
    //           }
    //         })
    //         thiz.localPostList = tempPostList
    //         thiz.localPostLoading = false
    //       })
    //   }
    // },
    // 摄影师的点击
    onUserDetail(id, code) {
      let routerUrl = this.$router.resolve({
        path: '/userShowDetail.html',
        query: { id: id, code: code },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 报名参赛
    onGoactivity(postId) {
      var name = '报名参赛'
      let routerUrl = this.$router.resolve({
        path: '/activityPage.html',
        query: { code: postId, type: name },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 摄影师注册页面
    onGoregister(type) {
      let routerUrl = this.$router.resolve({
        path: '/register.html',
        query: { id: type },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 摄影活动的图片点击进入详情
    onactivitydetail(postId) {
      let routerUrl = this.$router.resolve({
        path: '/activityPage.html',
        query: { code: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 最新图片的左移
    npGoRight(index) {
      this.contents[index].npIndex--
      if (this.contents[index].npIndex < 0) {
        this.contents[index].npIndex = this.contents[index].npList.length - 1
      }
      this.npListProcess(this.contents[index])
    },
    npGoLeft(index) {
      this.contents[index].npIndex++
      if (this.contents[index].npIndex >= this.contents[index].npList.length) {
        this.contents[index].npIndex = 0
      }
      this.npListProcess(this.contents[index])
    },
    /**
     * 处理nplist的位置函数，注入对应code入item的loc
     */
    npListProcess(content) {
      //npList npIndex
      let list = JSON.parse(JSON.stringify(content.npList))
      let index = content.npIndex
      list.forEach((item) => {
        item.loc = undefined
      })
      list[index].loc = 0
      list[index + 1 >= list.length ? index + 1 - list.length : index + 1].loc = 1
      list[index + 2 >= list.length ? index + 2 - list.length : index + 2].loc = 2
      list[index - 1 < 0 ? index - 1 + list.length : index - 1].loc = -1
      list[index - 2 < 0 ? index - 2 + list.length : index - 2].loc = -2
      content.npList = list
    },
    onVideoEnter(ref) {
      this.$refs[ref][0].play()
    },
    onVideoLeave(ref) {
      this.$refs[ref][0].pause()
    },
    // 稿件的更多
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 融媒人物的更多
    onUserShow() {
      let routerUrl = this.$router.resolve({
        path: '/userShowList.html',
        query: { code: 4 },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 具体摄影者
    onDetailUser(id) {
      let routerUrl = this.$router.resolve({
        path: '/userShowDetail.html',
        query: { id: id, code: 4 },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
.all {
  width: 100%;
  background-color: #f8f8f8;
  overflow: hidden;
}
.whole {
  width: 100%;
}
.desktop-present {
  .front-show {
    height: 60rem;
    width: 120rem;
    margin: 0 auto;
    position: relative;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    display: flex;
    .nav-content {
      position: fixed;
      top: 0rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      margin: 0 auto;
      z-index: 2;
      transition: 300ms all ease-in-out;
      .title {
        display: flex;
        height: 10rem;
        position: relative;
        width: 120rem;
        margin: 0 auto;
        .title-log {
          width: 30.5rem;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0rem;
          cursor: pointer;
          display: flex;
          .title-bg {
            height: 80%;
            width: 22%;
            background: no-repeat url(../assets/logo.png);
            background-size: contain;
            margin-top: 1.6rem;
          }
          .title-text {
            color: #fff;
            margin-top: 2.5rem;
            margin-left: 1rem;
            .title-large {
              font-size: 2.2rem;
              height: 3rem;
              line-height: 3rem;
              letter-spacing: 10px;
              margin-left: 1px;
            }
            .title-small {
              font-size: 1.2rem;
              text-align: left;
              transform: scale(1, 1.6);
              -ms-transform: scale(1, 1.6);
              -webkit-transform: scale(1, 1.6);
              -moz-transform: scale(1, 1.6);
              -o-transform: scale(1, 1.6);
            }
          }
        }
        .nav {
          width: 80rem;
          height: 100%;
          position: absolute;
          left: 31.6rem;
          display: flex;
          .nav-list {
            display: flex;
            width: 48rem;
            height: 10rem;
            line-height: 10rem;
            flex-shrink: 0;
            flex-wrap: wrap;
            .nav-detail {
              font-size: 1.8rem;
              cursor: pointer;
              position: relative;
              text-align: left;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: #fff;
                text-decoration: none;
                padding: 0px 0.8rem;
              }
              .nav-detail-subs {
                position: absolute;
                top: 8.3rem;
                left: 0px;
                width: 10rem;
                z-index: 99;
                overflow: hidden;
                .nav-detail-seq {
                  font-size: 1.5rem;
                  height: 0px;
                  background-color: #ff8c00;
                  color: #fff;
                  transition: 200ms all ease-in-out;
                  cursor: pointer;
                  a {
                    color: #fff;
                    text-decoration: none;
                    height: 0px;
                  }
                }
              }
            }
            .nav-detail:hover {
              color: #fff;
              background: #ff8c00;
              a {
                color: #fff;
              }
              .nav-detail-subs {
                display: block;
              }
              .nav-detail-seq {
                height: 2.5rem;
                line-height: 2.5rem;
                margin: 0.8rem 0px 0px 0px;
                a {
                  height: 2.5rem;
                  color: #fff;
                }
              }
            }
            .nav-detail.active {
              color: red;
            }
          }
          .search {
            width: auto;
            height: 2.5rem;
            margin-top: 1rem;
            .search-input {
              width: 21rem;
              overflow: hidden;
              height: 100%;
              background-color: rgba(0, 0, 0, 0);
              border-radius: 1.2rem;
              position: relative;
              margin-top: 2.5rem;
              .sinput {
                height: 100%;
                background-color: rgba(0, 0, 0, 0);
              }
              .search-icon {
                position: absolute;
                right: 0.8rem;
                top: 3px;
                height: 2.5rem;
                width: 1.8rem;
                font-size: 2rem;
                cursor: pointer;
              }
            }
          }
        }
        .nav-right {
          position: absolute;
          right: 1.5rem;
          top: 2.5rem;
          cursor: pointer;
          div {
            font-size: 1.6rem;
            color: #fff;
          }
        }
        .dialog {
          z-index: 10000 !important;
          .logo-in {
            text-align: left;
            margin-left: 12%;
            .name,
            .pwd,
            .code {
              margin-bottom: 0.8rem;
              span {
                width: 7.5rem;
                display: inline-block;
              }
              .el-input {
                width: 25rem;
              }
            }
            .code {
              display: flex;
              flex-wrap: nowrap;
              .code-img {
                width: 10rem;
                height: 4.2rem;
              }
            }
          }
          .el-button {
            height: 4rem;
          }
        }
      }
    }
    .nav-content.active {
      background: rgba(0, 0, 0, 0.6);
      transition: 300ms all ease-in-out;
    }
    .pic-container {
      position: relative;
      width: 70%;
      height: 100%;
      overflow: hidden;
      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        .pic-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }
        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }
      .front-pic.active {
        opacity: 1;
      }
    }
    .fixtopic-right {
      height: 100%;
      width: 28%;
      margin-left: 2%;
      .fixtopic-top,
      .fixtopic-bottom {
        height: 50%;
        overflow: hidden;
        .title {
          font-size: 2rem;
          font-weight: bold;
          text-align: left;
          height: 5rem;
          line-height: 5rem;
          cursor: pointer;
        }
        .content {
          .item {
            height: 2.8rem;
            font-size: 1.6rem;
            line-height: 2.8rem;
            text-align: left;
            cursor: pointer;
          }
        }
      }
    }
    .nav-container {
      position: absolute;
      bottom: 1.25rem;
      right: 53%;
      transform: translate(20rem);
      width: 25rem;
      height: 2.5rem;
      display: flex;
      justify-content: space-between;
      .nav-pic {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 0.66rem;
        border: 1px solid rgba(255, 255, 255, 0.6);
        margin-right: 0.85rem;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }
      .nav-pic.active {
        background-color: rgb(23, 47, 184);
        width: 4.2rem;
      }
    }
  }

  .fixtopic-ft {
    width: 120rem;
    margin: 0 auto;
    display: flex;
    margin-top: 2rem;
    height: auto;
    .ft-left {
      width: 69%;
      .ft-onecontent {
        height: 25.6rem;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        .ft-one,
        .ft-two,
        .ft-three {
          flex: 1;
          text-align: left;
          .title {
            font-size: 2.2rem;
            cursor: pointer;
          }
          .con {
            margin-top: 1rem;
            .item {
              height: 2.6rem;
              line-height: 2.5rem;
              font-size: 1.6rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: flex;
              margin: 1rem;
              cursor: pointer;
            }
          }
        }
        .ft-three {
          .con {
            margin-top: 1rem;
            .item {
              height: 10rem;
              line-height: 10rem;
              font-size: 1.6rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: flex;
              margin: 1rem;
              .us-img {
                width: 50%;
                height: 100%;
                background: no-repeat 50% / cover;
              }
            }
          }
        }
      }
      .ft-page,
      .ft-report {
        .title {
          text-align: left;
          font-size: 2.2rem;
          height: 5rem;
          line-height: 5rem;
          cursor: pointer;
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          justify-content: space-between;
          height: 45rem;
          .item {
            width: 27rem;
            height: 45rem;
            position: relative;
            overflow: hidden;
            transition: all 500ms ease-in-out;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / cover;
              transition: all 500ms ease-in-out;
              cursor: pointer;
            }
            .text {
              position: absolute;
              left: 50%;
              bottom: 0px;
              width: 90%;
              transform: translate(-50%);
              height: 4.2rem;
              line-height: 4.2rem;
              border-radius: 5px;
              color: #fff;
              font-size: 1.5rem;
              background-color: rgba(0, 0, 0, 0.3);
              transition: all 500ms ease-in-out;
              cursor: pointer;
            }
          }
          .item:hover {
            .img {
              transform: scale(1.05);
            }
            .text {
              width: 95%;
              background-color: rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
      .ft-prop {
        height: 54.5rem;
        display: flex;
        margin-top: 2rem;
        .ft-ac {
          width: 70%;
          height: 100%;
          .title {
            text-align: left;
            height: 5rem;
            line-height: 5rem;
            font-size: 2.2rem;
            cursor: pointer;
          }
          .con {
            height: 90%;
            position: relative;
            overflow: hidden;
            transition: all 500ms ease-in-out;
            .ac-img {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / cover;
              transition: all 500ms ease-in-out;
            }
            .text {
              position: absolute;
              left: 50%;
              bottom: 0px;
              width: 90%;
              transform: translate(-50%);
              height: 4.2rem;
              line-height: 4.2rem;
              border-radius: 5px;
              color: #fff;
              font-size: 1.5rem;
              background-color: rgba(0, 0, 0, 0.3);
              transition: all 500ms ease-in-out;
            }
          }
          .con:hover {
            .ac-img {
              transform: scale(1.05);
            }
            .text {
              width: 95%;
              background-color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .ft-report {
          width: 28%;
          margin-left: 1rem;
          .title {
            text-align: left;
            font-size: 2.2rem;
            cursor: pointer;
          }
          .content {
            height: 93%;
            .item {
              height: 47%;
              position: relative;
            }
          }
        }
      }
    }
    .ft-right {
      width: 29%;
      margin-left: 2rem;

      .ft-one,
      .ft-three {
        height: auto;
        .title {
          font-size: 2.2rem;
          text-align: left;
          height: 6rem;
          line-height: 6rem;
          cursor: pointer;
        }
        .content {
          height: 28rem;
          width: 100%;
          transition: all 500ms ease-in-out;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          .img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .text {
            position: absolute;
            left: 50%;
            bottom: 0px;
            width: 90%;
            transform: translate(-50%);
            height: 4.2rem;
            line-height: 4.2rem;
            border-radius: 5px;
            color: #fff;
            font-size: 1.5rem;
            background-color: rgba(0, 0, 0, 0.3);
            transition: all 500ms ease-in-out;
          }
        }
        .content:hover {
          .img {
            transform: scale(1.05);
          }
          .text {
            width: 95%;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
      .ft-one {
        .title {
          font-size: 2.2rem;
          text-align: left;
          height: 4rem;
          line-height: 4rem;
          cursor: pointer;
        }
        .content {
          height: 60.5rem;
          width: 100%;
          .ft-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
        }
      }
      .ft-two {
        .title {
          font-size: 2.2rem;
          text-align: left;
          height: 6rem;
          line-height: 6rem;
          cursor: pointer;
        }
        .video-con {
          .video-item {
            position: relative;
            cursor: pointer;
            transition: all 500ms ease-in-out;
            video {
              width: 100%;
              object-fit: cover;
              background-color: black;
              position: relative;
              cursor: pointer;
            }
            .mute {
              display: block;
              position: absolute;
              right: 2rem;
              top: 2rem;
              font-size: 2rem;
              height: 3rem;
              width: 3rem;
              border: 2px solid white;
              border-radius: 2rem;
              line-height: 3rem;
              color: white;
            }
            .mute.active {
              color: red;
            }
            .video-text {
              position: absolute;
              bottom: 0px;
              width: 100%;
              height: 4.2rem;
              line-height: 4.2rem;
              color: #fff;
              font-size: 1.5rem;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
          .video-item:hover {
            .video-text {
              background-color: rgba(0, 0, 0, 0.4);
            }
          }
          .video-item:nth-child(1n + 2) {
            display: none;
          }
        }
      }
    }
  }

  .np {
    width: 120rem;
    height: 27.5rem;
    overflow: hidden;
    margin: 3.33rem auto;
    position: relative;
    .np-content {
      overflow: hidden;
      width: 120rem;
      height: 27.5rem;
      margin: 0 auto;
      .np-title {
        position: absolute;
        top: 0px;
        left: 42.4rem;
        width: 33.4rem;
        height: 6.7rem;
        z-index: 90;
        .name {
          height: 5rem;
          line-height: 5rem;
          cursor: pointer;
          font-size: 2.2rem;
          width: 16.7rem;
          margin: 0 auto;
        }
        .underline {
          position: absolute;
          left: 35%;
          top: 54px;
          width: 47%;
          height: 0.42rem;
          background-color: #cf0000;
        }
        .more {
          position: absolute;
          left: 67%;
          top: 1.7rem;
          height: 6.25rem;
          width: 5rem;
          font-size: 1.7rem;
          cursor: pointer;
        }
      }
      .np-list {
        width: 120rem;
        height: 25rem;
        position: absolute;
        bottom: 0px;
        left: 0px;
        .np-detail {
          height: 19.16rem;
          width: 36.6rem;
          overflow: hidden;
          position: absolute;
          bottom: 75rem;
          left: 33.4rem;
          transition: all 300ms ease-in-out;
          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
            transition: all 300ms ease-in-out;
            position: relative;
            a {
              height: 100%;
              width: 100%;
              display: block;
            }
          }
          .text {
            position: absolute;
            bottom: 0px;
            font-size: 1.7rem;
            text-align: center;
            height: 3.33rem;
            line-height: 3.33rem;
            width: calc(100% - 3.33rem);
            padding: 0px 1.7rem;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: all 300ms ease-in-out;
          }
        }
        .np-detail:hover {
          .img {
            transform: scale(1.05);
          }
          .text {
            font-size: 2rem;
            background: rgba(0, 0, 0, 0.4);
          }
        }
        .np-detail.active {
          bottom: 0px;
          left: 42.2rem;
        }
        .np-detail.next-1 {
          bottom: 5rem;
          left: 83.3rem;
        }
        .np-detail.next-2 {
          bottom: 33.4rem;
          left: 101rem;
        }
        .np-detail.pre-1 {
          bottom: 5rem;
          left: 0px;
        }
        .np-detail.pre-2 {
          bottom: 33.4rem;
          left: -33.3rem;
        }
      }
      .np-left {
        left: 0px;
      }
      .np-right {
        right: 0px;
      }
      .np-button {
        position: absolute;
        bottom: 0px;
        height: 4.2rem;
        width: 31.6rem;
        line-height: 4.2rem;
        font-size: 3.33rem;
        color: rgb(255, 255, 255);
        // background-color: rgba(0, 0, 0, 0.1);
      }
      .el-icon-back,
      .el-icon-right {
        color: #ccc;
        font-size: 4rem;
      }
      .np-button:hover {
        color: #cf0000;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      .np-button:active {
        color: #cf0000;
        font-size: 3.75rem;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .np-transform {
      height: 4.2rem;
      margin-top: 1.7rem;
      .np-left {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }
      .el-icon-back {
        font-size: 3.33rem;
      }
      .el-icon-right {
        font-size: 3.33rem;
      }
      .np-right {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }
    }
  }

  .news {
    width: 120rem;
    margin: 3.33rem auto;
    position: relative;
    .news-title {
      position: relative;
      height: 10rem;
      line-height: 10rem;
      .news-name {
        width: 16.7rem;
        margin: 0 auto;
        font-size: 2.2rem;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 46%;
        top: 7.5rem;
        width: 10rem;
        height: 0.42rem;
        background-color: #cf0000;
      }
      .news-more {
        position: absolute;
        left: 57%;
        top: 0.85rem;
        height: 6.25rem;
        width: 5rem;
        font-size: 1.7rem;
        cursor: pointer;
      }
    }
    .news-con {
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      .news-detail {
        height: auto;
        width: 205%;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        .news-list {
          width: 31.9rem;
          height: 233px;
          position: relative;
          .news-img {
            height: 235px;
            background-size: cover;
          }
          .news-list-name {
            position: absolute;
            bottom: 0px;
            font-size: 1.7rem;
            text-align: center;
            height: 3.33rem;
            width: 100%;
            line-height: 3.33rem;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            cursor: pointer;
          }
          .news-list-text {
            color: #c6c6c6;
            font-size: 1.2rem;
            margin: 0px 1.25rem;
            text-align: left;
          }
        }
      }
    }

    .shortVideo {
      height: auto;
      width: 120rem;
      margin: 0 auto;
      .shortVideo-con {
        display: flex;
        .shortVidoe-left {
          width: 82%;
          height: auto;
          margin-right: 2%;
          .shortVideo-left-title {
            height: 6.7rem;
            margin-top: 3.33rem;
            cursor: pointer;
            position: relative;
            .shortVideo-name {
              font-size: 2.2rem;
              width: 16.7rem;
              margin: 0 auto;
            }
            .red {
              position: absolute;
              left: 43%;
              top: 43px;
              width: 10rem;
              height: 0.42rem;
              background-color: #cf0000;
            }
          }
          .shortVideo-left-con {
            height: 42.75rem;
            width: 100%;
            border: 1px solid #ccc;
            .shortVideo-left-con-img {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
              background-color: #000;
              position: relative;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .rd-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / cover;
                cursor: pointer;
                position: absolute;
                top: 0px;
                left: 0px;
              }
              .video-text {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 4.2rem;
                line-height: 4.2rem;
                color: #fff;
                font-size: 1.5rem;
                background-color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
        .shortVideo-right {
          width: 28%;
          height: 100%;
          position: relative;
          .shortVideo-right-con {
            .shortVideo-right-list {
              height: 12.9rem;
              width: 100%;
              position: relative;
              margin-bottom: 2rem;
              background-color: #000;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .rd-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                cursor: pointer;
                position: absolute;
                top: 0px;
                left: 0px;
              }
              .video-text {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 4.2rem;
                line-height: 4.2rem;
                color: #fff;
                font-size: 1.5rem;
                background-color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }

    .materialVideo {
      height: auto;
      width: 120rem;
      margin: 0 auto;
      .materialVideo-list {
        display: flex;
        .material-item {
          width: 38.3rem;
          height: 20rem;
          position: relative;
          margin-right: 1rem;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mv-img {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
          }
          .mv-text {
            position: absolute;
            bottom: 0px;
            height: 4.2rem;
            width: 100%;
            line-height: 4.2rem;
            color: #fff;
            font-size: 1.5rem;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .tuku-con {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 700px;
      overflow: hidden;
      .tuku-list {
        width: 31.9rem;
        height: 28.75rem;
        margin-bottom: 1.7rem;
        display: flex;
        .tuku-list-left {
          width: 10%;
          position: relative;
          .tuku-list-title {
            cursor: pointer;
          }
          div {
            width: 1.7rem;
            font-size: 1.8rem;
            margin-left: 0.85rem;
            writing-mode: vertical-rl;
          }
          span {
            display: inline-block;
            width: 1.7rem;
            position: absolute;
            writing-mode: vertical-rl;
            bottom: 3.5rem;
            right: 0.85rem;
            font-size: 1.3rem;
            cursor: pointer;
          }
        }
        .tuku-list-right {
          width: 90%;
          .tuku-list-rightimg {
            height: 25.4rem;
            background-size: cover;
          }
          .tuku-list-righttext {
            height: 3.33rem;
            line-height: 3.33rem;
            font-size: 1.3rem;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
      .tu-more {
        color: #918d8c;
        position: relative;
        margin-left: 1.7rem;
        .tuku-more-text {
          width: 28.7rem;
          background-color: #dfdbda;
          height: 307px;
          position: absolute;
          left: 38.3px;
          top: 0px;
          .tuku-all {
            position: absolute;
            left: 30%;
            top: 38%;
            height: 2.5rem;
            width: 12.5rem;
            font-size: 2rem;
          }
          .clip {
            position: absolute;
            top: 52%;
            left: 45%;
            display: flex;
            height: 4.2rem;
            width: 10rem;
            div {
              height: 0.85rem;
              width: 0.85rem;
              border-radius: 0.42rem;
              background-color: #918d8c;
              margin-right: 0.85rem;
            }
          }
        }
      }
    }
    .tuku-btn {
      position: absolute;
      bottom: 1.67rem;
      right: -6.67rem;
      width: 5rem;
      height: 5rem;
      border: 2px solid #acacac;
      .tuku-btn-right {
        width: 1.7rem;
        height: 1.7rem;
        border-top: 2px solid #acacac;
        border-right: 2px solid #acacac;
        transform: rotate(45deg);
        position: absolute;
        left: 1.2rem;
        top: 1.5rem;
      }
    }
    .news-btn {
      position: absolute;
      right: -6.67rem;
      bottom: 19.75rem;
      width: 5.5rem;
      height: 8.3rem;
      .news-btn-right {
        width: 3rem;
        height: 3rem;
        border-radius: 3.33rem;
        border: 0.42rem solid #ff8213;
        margin-bottom: 0.85rem;
        position: relative;
        .news_btn_right {
          width: 1rem;
          height: 1rem;
          border-top: 2px solid #ffce9f;
          border-right: 2px solid #ffce9f;
          transform: rotate(45deg);
          position: absolute;
          left: 0.66rem;
          top: 1rem;
        }
      }
      .news-btn-left {
        width: 3rem;
        height: 3rem;
        border-radius: 3.33rem;
        border: 0.42rem solid #f6b887;
        position: relative;
        .news_btn_left {
          width: 1rem;
          height: 1rem;
          border-top: 2px solid #ffce9f;
          border-left: 2px solid #ffce9f;
          transform: rotate(-45deg);
          position: absolute;
          left: 1.2rem;
          top: 1rem;
        }
      }
    }
    .culture-con {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto;
      overflow: hidden;
      .culture-list {
        width: 58.3rem;
        height: 32.25rem;
        .culture-img {
          height: 28.6rem;
          width: 100%;
          background-size: cover;
          cursor: pointer;
        }
        .culture-text {
          font-size: 1.5rem;
          display: flex;
          .icon {
            display: inline-block;
            width: 4.5rem;
            height: 3.5rem;
            margin-left: 22.4rem;
            // background: no-repeat url(../assets/culturetwo.png);
            background-size: cover;
          }
          .culture-t {
            cursor: pointer;
          }
          div {
            height: 4.2rem;
            line-height: 4.2rem;
          }
        }
      }
    }
    .edu-con {
      display: flex;
      justify-content: space-between;
      border: 0.66rem solid #c7c5c6;
      border-radius: 0.42rem;
      padding: 2.5rem;
      margin-top: 1.7rem;
      .edu-list {
        width: 29.17rem;
        height: 16.7rem;
        div {
          width: 29.17rem;
          height: 16.7rem;
          background-size: cover;
        }
      }
    }
    .zhuanti-con {
      display: flex;
      flex-shrink: 0;
      .zhuanti-left {
        width: 65%;
        height: 39.7rem;
        position: relative;
        overflow: hidden;
        .zhuanti-left-img {
          width: 100%;
          height: 39.7rem;
          position: relative;
          overflow: hidden;
          transition: all 200ms ease-in-out;
          overflow: hidden;
          background-size: cover;
          cursor: pointer;
        }
        .zhunti-con-text {
          position: absolute;
          bottom: 0px;
          height: 4.2rem;
          width: 100%;
          line-height: 4.2rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          text-align: center;
          font-size: 1.3rem;
          transition: all 200ms ease-in-out;
          overflow: hidden;
        }
      }
      .zhuanti-left:hover {
        .zhuanti-left-img {
          transform: scale(1.05);
        }
        .zhunti-con-text {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .zhuanti-right {
        width: 35%;
        background: #000;
        margin-left: 0.85rem;
        .zhuanti-right-top {
          width: 27.8rem;
          height: 86%;
          overflow: hidden;
          .zhuanti-right-img {
            width: 27.8rem;
            height: 16.7rem;
            margin-bottom: 0.85rem;
            background-size: cover;
            cursor: pointer;
            position: relative;
            transition: all 200ms ease-in-out;
            overflow: hidden;
            .zhuanti-right-text {
              position: absolute;
              bottom: 0px;
              height: 3.33rem;
              width: 100%;
              line-height: 3.33rem;
              background-color: rgba(0, 0, 0, 0.3);
              color: #fff;
              text-align: center;
              font-size: 1.3rem;
              transition: all 200ms ease-in-out;
            }
          }
          .zhuanti-right-img:hover {
            transform: scale(1.05);
            .zhuanti-right-text {
              background-color: rgba(0, 0, 0, 0.5);
            }
          }
        }
        .zhuanti-right-bottom {
          height: 4.67rem;
          width: 100%;
          .zhuanti-right-text {
            width: 25rem;
            color: #fff;
            font-size: 2rem;
            margin-left: 0.85rem;
          }
          .zhuanti-right-clip {
            display: flex;
            margin-left: 4.2rem;
            div {
              width: 1rem;
              height: 1rem;
              border-radius: 0.5rem;
              background: #c7c5c6;
              margin-right: 0.85rem;
              margin-left: 1.3rem;
              margin-top: 0.85rem;
            }
          }
        }
      }
    }
  }
  .sky {
    width: 100%;
    margin: 1.7rem auto;
    position: relative;
    .sky-title {
      position: relative;
      height: 10rem;
      width: 120rem;
      margin: 1.7rem auto;
      line-height: 10rem;
      .sky-name {
        width: 16.7rem;
        margin: 0 auto;
        font-size: 2.2rem;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 46%;
        top: 7.5rem;
        width: 10rem;
        height: 0.42rem;
        background-color: #cf0000;
      }
    }
    .sky-con {
      height: 79rem;
      position: relative;
      .sky-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }
      .sky-pic.active {
        opacity: 1;
      }
    }
    .sky-text {
      display: flex;
      margin: 1.25rem auto;
      position: absolute;
      bottom: 0px;
      width: 100%;
      justify-content: center;
      .nav-pic {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 0.66rem;
        border: 1px solid rgba(255, 255, 255, 0.4);
        margin-right: 0.85rem;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }
      .nav-pic.active {
        background-color: rgb(23, 47, 184);
        width: 3.75rem;
      }
    }
    .sky-two {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      .skytwo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-shrink: 0;
        width: 170%;
        .skytwo-con {
          width: 39.5rem;
          height: 20rem;
          position: relative;
          overflow: hidden;
          .skytwo-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .skytwo-text {
            position: absolute;
            bottom: 0.66rem;
            left: 50%;
            transform: translate(-50%);
            width: 85%;
            border-radius: 0.42rem;
            font-size: 1.3rem;
            text-align: center;
            height: 3.9rem;
            line-height: 3.9rem;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: all 500ms ease-in-out;
          }
        }
        .skytwo-con:hover {
          .skytwo-img {
            transform: scale(1.05);
          }
          .skytwo-text {
            background-color: rgba(0, 0, 0, 0.4);
            width: 92%;
          }
        }
      }
    }
    .view-three {
      height: 41.6rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      .view-three-left {
        width: 78.7rem;
        height: 100%;
        margin-right: 1.7rem;
        position: relative;
        overflow: hidden;
        .viewthree-left-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .viewthree-con-text {
          position: absolute;
          bottom: 0px;
          transition: all 500ms ease-in-out;
          width: 100%;
          color: #fff;
          font-size: 1.3rem;
          height: 2.5rem;
          line-height: 2.5rem;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
      .view-three-left:hover {
        .viewthree-left-img {
          transform: scale(1.05);
        }
        .viewthree-con-text {
          height: 3.9rem;
          font-size: 1.4rem;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
      .view-three-right {
        width: 41.3rem;
        height: 100%;
        .viewthree-right {
          width: 100%;
          height: 48%;
          margin-bottom: 1.7rem;
          position: relative;
          overflow: hidden;
          background-color: #fff;
          .gonggao-title,
          .tuku-title {
            width: 100%;
            height: 4rem;
            background-color: #dfdde0;
            font-size: 1.8rem;
            line-height: 4rem;
            font-weight: bold;
            letter-spacing: 5px;
          }
          .gonggao-con {
            height: 17rem;
            overflow: hidden;
            margin-left: 0.5rem;
            .gonggao-pre {
              text-align: left;
              height: 2rem;
              font-size: 1.4rem;
              // line-height: 4rem;
              span {
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #000;
              }
            }
          }
          .tuku-con {
            display: flex;
            flex-wrap: wrap;
            width: 32rem;
            margin: 0 auto;
            .tuku-pre {
              width: 8rem;
              height: 4rem;
              line-height: 4rem;
              cursor: pointer;
            }
          }
          .viewthree-right-text {
            position: absolute;
            bottom: 0px;
            width: 100%;
            color: #fff;
            transition: all 500ms ease-in-out;
            font-size: 1.3rem;
            height: 2.5rem;
            line-height: 2.5rem;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        .viewthree-right:hover {
          .viewthree-right-img {
            transform: scale(1.05);
          }
          .viewthree-right-text {
            height: 3.9rem;
            font-size: 1.4rem;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }

    .ft-threedouble {
      height: 44rem;
      width: 120rem;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .threedouble-con {
        width: 38.5rem;
        height: 20rem;
        position: relative;
        margin-bottom: 2rem;
        .three-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .three-text {
          position: absolute;
          bottom: 0.66rem;
          left: 50%;
          transform: translate(-50%);
          width: 85%;
          border-radius: 0.42rem;
          font-size: 1.3rem;
          text-align: center;
          height: 3.9rem;
          line-height: 3.9rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          transition: all 500ms ease-in-out;
        }
      }
      .threedouble-con:hover {
        .three-img {
          transform: scale(1.05);
        }
        .three-text {
          background-color: rgba(0, 0, 0, 0.4);
          width: 92%;
        }
      }
    }
    .ft-four {
      height: auto;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      .ft-four-left {
        width: 32%;
        .ft-four-left-con {
          height: 20rem;
          width: 36.8rem;
          margin-bottom: 1.7rem;
          position: relative;
          overflow: hidden;
          .ft-four-left-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .ft-four-left-text {
            position: absolute;
            bottom: 0.66rem;
            left: 50%;
            transform: translate(-50%);
            width: 85%;
            border-radius: 0.42rem;
            font-size: 1.3rem;
            text-align: center;
            height: 3.9rem;
            line-height: 3.9rem;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: all 500ms ease-in-out;
          }
        }
        .ft-four-left-con:hover {
          .ft-four-left-img {
            transform: scale(1.05);
          }
          .ft-four-left-text {
            background-color: rgba(0, 0, 0, 0.4);
            width: 92%;
          }
        }
      }
      .ft-four-right {
        width: 68%;
        .ft-four-right-con {
          height: 41.6rem;
          position: relative;
          overflow: hidden;
          .ft-four-right-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .ft-four-right-text {
            position: absolute;
            bottom: 0.66rem;
            left: 50%;
            transform: translate(-50%);
            width: 85%;
            border-radius: 0.42rem;
            font-size: 1.3rem;
            text-align: center;
            height: 3.9rem;
            line-height: 3.9rem;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: all 500ms ease-in-out;
          }
        }
        .ft-four-right-con:hover {
          .ft-four-right-img {
            transform: scale(1.05);
          }
          .ft-four-right-text {
            background-color: rgba(0, 0, 0, 0.4);
            width: 92%;
          }
        }
      }
    }
    .ft-14 {
      height: 40rem;
      width: 120rem;
      margin: 0px auto;
      position: relative;
      .item-con {
        position: absolute;
        overflow: hidden;
        .item-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .item-text {
          position: absolute;
          bottom: 0.66rem;
          left: 50%;
          transform: translate(-50%);
          width: 85%;
          border-radius: 0.42rem;
          font-size: 1.3rem;
          text-align: center;
          height: 3.9rem;
          line-height: 3.9rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          transition: all 500ms ease-in-out;
        }
      }
      .item-con:hover {
        .item-img {
          transform: scale(1.05);
        }
        .item-text {
          background-color: rgba(0, 0, 0, 0.8);
          font-size: 1.6rem;
          width: 92%;
        }
      }
      .item-con:nth-child(1) {
        left: 0px;
        top: 0px;
        height: 40rem;
        width: 60rem;
      }
      .item-con:nth-child(2) {
        left: 62rem;
        top: 0px;
        height: 19rem;
        width: 28rem;
      }
      .item-con:nth-child(3) {
        left: 92rem;
        top: 0px;
        height: 19rem;
        width: 28rem;
      }
      .item-con:nth-child(4) {
        left: 62rem;
        top: 21rem;
        height: 19rem;
        width: 28rem;
      }
      .item-con:nth-child(5) {
        left: 92rem;
        top: 21rem;
        height: 19rem;
        width: 28rem;
      }
      .item-con:nth-child(n + 6) {
        display: none;
      }
    }

    .ft-4s {
      height: 25rem;
      width: 120rem;
      margin: 0px auto;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1%;
      .item-con {
        position: relative;
        overflow: hidden;
        height: 100%;
        flex: 1;
        .item-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .item-text {
          position: absolute;
          bottom: 0.66rem;
          left: 50%;
          transform: translate(-50%);
          width: 85%;
          border-radius: 0.42rem;
          font-size: 1.3rem;
          text-align: center;
          height: 3.9rem;
          line-height: 3.9rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          transition: all 500ms ease-in-out;
        }
      }
      .item-con:hover {
        .item-img {
          transform: scale(1.05);
        }
        .item-text {
          background-color: rgba(0, 0, 0, 0.4);
          width: 92%;
        }
      }
      .item-con:nth-child(n + 5) {
        display: none;
      }
    }
  }

  .local {
    width: 120rem;
    margin: 1.7rem auto;
    position: relative;
    .local-title {
      position: relative;
      height: 10rem;
      width: 91.6rem;
      margin: 1.7rem auto;
      line-height: 10rem;
      .local-name {
        width: 18rem;
        margin: 0 auto;
        font-size: 2.2rem;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 43%;
        top: 7.5rem;
        width: 13.1rem;
        height: 0.42rem;
        background-color: #cf0000;
      }
    }
    .local-con {
      .local-list {
        width: 69rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .local-list-name {
          height: auto;
          transition: all 400ms ease-in-out;
          font-size: 1.5rem;
          width: auto;
          padding: 0.85rem 2.5rem;
          border: 1px solid #ccc;
          border-radius: 2.5rem;
          margin-right: 0.85rem;
          margin-top: 0.85rem;
          background-color: #ebedf1;
          cursor: pointer;
        }
        .active {
          background-color: #d42d26;
          color: #fff;
        }
      }
      .local-detail {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-shrink: 0;
        margin-top: 3.33rem;
        overflow: hidden;
        transition: all 400ms ease-in-out;
        .local-detail-list {
          height: 20.75rem;
          width: 28.6rem;
          margin-right: 0.66rem;
          background-color: #ebedf1;
          margin-bottom: 1.7rem;
          position: relative;
          overflow: hidden;
          .local-detail-img {
            height: 20.75rem;
            width: 100%;
            background: no-repeat 50% / cover;
            cursor: pointer;
            transition: all 300ms ease-in-out;
          }
          .local-detail-text {
            height: 2.5rem;
            width: 100%;
            line-height: 2.5rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1.3rem;
            color: #000;
            cursor: pointer;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            bottom: 0px;
            background-color: rgba(255, 255, 255, 0.4);
            transition: all 400ms ease-in-out;
            padding: 0px 0.42rem;
          }
          .local-detail-picsnumber {
            height: 2.5rem;
            width: 100%;
            line-height: 2.5rem;
            font-size: 1.2rem;
            position: absolute;
            bottom: -8.33rem;
            background-color: rgba(255, 255, 255, 0.4);
            transition: all 600ms ease-in-out;
            left: 50%;
            transform: translate(-50%);
          }
        }
        .local-detail-list:nth-child(1) {
          width: 58.8rem;
          height: 20.75rem;
          margin-right: 0.85rem;
          background-color: #ebedf1;
        }
        .local-detail-list:hover {
          .local-detail-text {
            bottom: 3.75rem;
            border-radius: 0.42rem;
            width: 90%;
            background-color: rgba(255, 255, 255, 0.8);
          }
          .local-detail-picsnumber {
            bottom: 0.42rem;
            width: 90%;
            border-radius: 0.42rem;
            background-color: rgba(255, 255, 255, 0.8);
          }
          .local-detail-img {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .activity {
    width: 120rem;
    margin: 1.7rem auto;
    position: relative;
    .activity-title {
      position: relative;
      height: 10rem;
      width: 120rem;
      margin: 1.7rem auto;
      line-height: 10rem;
      .activity-name {
        width: 15rem;
        margin: 0 auto;
        font-size: 2.2rem;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 46%;
        top: 7.5rem;
        width: 10rem;
        height: 0.42rem;
        background-color: #cf0000;
      }
    }
    .activity-con {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      width: 102%;
      .activity-list {
        width: 38.4rem;
        height: 22.3rem;
        margin-right: 3.5rem;
        display: flex;
        .activity-left {
          width: 20.1rem;
          height: 100%;
          background-size: cover;
          cursor: pointer;
        }
        .activity-right {
          text-align: left;
          padding-left: 0.85rem;
          width: 16.75rem;
          background-color: #fff;
          .ac-right-title {
            color: red;
            font-size: 1.5rem;
            min-height: 3.33rem;
            margin-top: 1.7rem;
          }
          .ac-right-conmany {
            font-size: 1.5rem;
            margin-bottom: 0.42rem;
            min-height: 3.33rem;
          }
          .ac-right-link {
            font-size: 1.5rem;
            margin-bottom: 0.42rem;
            min-height: 3.33rem;
            display: flex;
            .link-one {
              width: 8.3rem;
              height: 2.5rem;
              line-height: 2.5rem;
              border: 0.25rem solid red;
              color: red;
            }
            .link-two {
              width: 5rem;
              height: 3rem;
              background-color: red;
              cursor: pointer;
              .sanjiao {
                border-width: 1rem;
                border-color: transparent transparent transparent #fff;
                border-style: solid;
                margin-left: 1.7rem;
                margin-top: 0.42rem;
              }
            }
          }
          .ac-right-text {
            height: 4.2rem;
            font-size: 1.5rem;
            margin-bottom: 0.42rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  .userShow {
    width: 100rem;
    margin: 0px auto;
    position: relative;
    .userShow-title {
      position: relative;
      height: 10rem;
      line-height: 10rem;
      .userShow-name {
        width: 15rem;
        margin: 0 auto;
        font-size: 2.2rem;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 44%;
        top: 7.5rem;
        width: 11rem;
        height: 0.42rem;
        background-color: #cf0000;
      }
    }
    .userShow-con {
      width: 100rem;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      height: 15.5rem;
      .userShow-swiper {
        display: flex;
        flex-wrap: nowrap;
        flex-shrink: 0;
        width: 116%;
        position: absolute;
        .userShow-list {
          width: 18.3rem;
          height: 18.3rem;
          .userShow-img {
            width: 10rem;
            height: 10rem;
            border-radius: 5rem;
            border: 0.083 solid #ccc;
            background-size: cover;
            margin-left: 3rem;
            cursor: pointer;
          }
          .userShow-text {
            height: 3.33rem;
            line-height: 3.33rem;
            font-size: 1.5rem;
            cursor: pointer;
          }
        }
        .transformUp {
          transform: translateX(-14.5rem);
          transition: all 1000ms ease-in-out;
        }
      }
    }
  }

  .ad {
    width: 120rem;
    margin: 0 auto;
    .ad-con {
      height: auto;
      width: 100%;
      a {
        width: 100%;
        .ad-detail {
          width: 100%;
        }
      }
    }
  }

  .link {
    width: 120rem;
    margin: 0 auto;
    .link-title {
      height: 6.7rem;
      display: flex;
      position: relative;
      .link-name {
        width: 140px;
        text-align: left;
        font-size: 2.2rem;
        margin-top: 1.7rem;
      }
      .clip {
        position: absolute;
        left: 10.6rem;
        top: 3.9rem;
        width: 108rem;
        height: 0.85rem;
        background: #d9d9d9;
      }
    }
    .link-con {
      overflow: hidden;
      .link-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .link-detail {
          width: auto;
          height: 5.5rem;
          line-height: 5.5rem;
          margin: 0.42rem 1.25rem 0.42rem 0px;
          padding: 0.42rem 1.25rem 0.42rem 0px;
          a {
            height: 100%;
            width: 100%;
            display: inline-block;
            text-decoration: none;
            color: #000;
            font-size: 1.3rem;
          }
        }
        .transformLeft {
          transform: translateX(-54rem);
          transition: all 800ms ease-in-out;
        }
      }
    }
  }

  .ifame-container {
    height: 29.17rem;
    background-color: #f1f1f1;
    margin-bottom: 1.7rem;
    width: 100rem;
    margin: 0 auto;
    .ifame-detail {
      width: 100rem;
      margin: 0 auto;
    }
  }
}
.mobile-present {
  width: 750px;
  .front-show {
    height: 486px;
    position: relative;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    .pic-container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        .pic-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }
        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }
      .front-pic.active {
        opacity: 1;
      }
    }
    .nav-container {
      position: absolute;
      bottom: 10px;
      right: 53%;
      transform: translate(20rem);
      width: 215px;
      height: 15px;
      display: flex;
      justify-content: space-between;
      .nav-pic {
        height: 12.5px;
        width: 12.5px;
        border-radius: 6.6px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        margin-right: 8.5px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }
      .nav-pic.active {
        background-color: rgb(23, 47, 184);
        width: 42px;
      }
    }
  }

  .np {
    width: 750px;
    height: 255px;
    overflow: hidden;
    margin: 33.3px auto;
    position: relative;
    .np-content {
      overflow: hidden;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .np-title {
        position: absolute;
        top: 0px;
        left: 226px;
        width: 284px;
        height: 67px;
        z-index: 90;
        .name {
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          font-size: 22px;
          width: 200px;
          margin: 0 auto;
        }
        .underline {
          position: absolute;
          left: 28%;
          top: 54px;
          width: 50%;
          height: 4px;
          background-color: #cf0000;
        }
        .more {
          position: absolute;
          left: 67%;
          top: 10px;
          height: 40px;
          width: 50px;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .np-list {
        width: 750px;
        height: 150px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        overflow: hidden;
        .np-detail {
          height: 116px;
          width: 222px;
          overflow: hidden;
          position: absolute;
          bottom: 750px;
          left: 222px;
          transition: all 300ms ease-in-out;
          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
            transition: all 300ms ease-in-out;
            position: relative;
            a {
              height: 100%;
              width: 100%;
              display: block;
            }
          }
          .text {
            position: absolute;
            bottom: 0px;
            font-size: 16px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            width: calc(100% - 30px);
            padding: 0px 16px;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: all 300ms ease-in-out;
          }
        }
        .np-detail:hover {
          .img {
            transform: scale(1.05);
          }
          .text {
            font-size: 2rem;
            background: rgba(0, 0, 0, 0.4);
          }
        }
        .np-detail.active {
          bottom: 0px;
          left: 266px;
        }
        .np-detail.next-1 {
          bottom: 40px;
          left: 510px;
        }
        .np-detail.next-2 {
          bottom: 334px;
          left: 1001px;
        }
        .np-detail.pre-1 {
          bottom: 40px;
          left: 20px;
        }
        .np-detail.pre-2 {
          bottom: 334px;
          left: -333px;
        }
      }
      .np-left {
        left: 0px;
      }
      .np-right {
        right: 0px;
      }
      .np-button {
        position: absolute;
        bottom: -8px;
        height: 40px;
        width: 240px;
        line-height: 33px;
        font-size: 30px;
        color: rgb(255, 255, 255);
        // background-color: rgba(0, 0, 0, 0.1);
      }
      .el-icon-back,
      .el-icon-right {
        color: #ccc;
        font-size: 30px;
      }
      .np-button:hover {
        color: #cf0000;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      .np-button:active {
        color: #cf0000;
        font-size: 3.75rem;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .np-transform {
      height: 4.2rem;
      margin-top: 1.7rem;
      .np-left {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }
      .el-icon-back {
        font-size: 3.33rem;
      }
      .el-icon-right {
        font-size: 3.33rem;
      }
      .np-right {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }
    }
  }

  .news {
    width: 750px;
    margin: 33px auto;
    position: relative;
    .news-title {
      position: relative;
      height: 60px;
      line-height: 60px;
      .news-name {
        width: 120px;
        margin: 0 auto;
        font-size: 22px;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 42%;
        top: 45px;
        width: 120px;
        height: 4px;
        background-color: #cf0000;
      }
      .news-more {
        position: absolute;
        left: 57%;
        top: 0.85rem;
        height: 6.25rem;
        width: 5rem;
        font-size: 1.7rem;
        cursor: pointer;
      }
    }
    .news-con {
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      .news-detail {
        height: auto;
        width: 205%;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        .news-list {
          width: 31.9rem;
          height: 233px;
          position: relative;
          .news-img {
            height: 235px;
            background-size: cover;
          }
          .news-list-name {
            position: absolute;
            bottom: 0px;
            font-size: 1.7rem;
            text-align: center;
            height: 3.33rem;
            width: 100%;
            line-height: 3.33rem;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            cursor: pointer;
          }
          .news-list-text {
            color: #c6c6c6;
            font-size: 1.2rem;
            margin: 0px 1.25rem;
            text-align: left;
          }
        }
      }
    }

    .shortVideo {
      height: auto;
      width: 750px;
      margin: 0 auto;
      .shortVideo-con {
        display: flex;
        .shortVidoe-left {
          width: 82%;
          height: auto;
          margin-right: 2%;
          .shortVideo-left-title {
            height: 6.7rem;
            margin-top: 3.33rem;
            cursor: pointer;
            position: relative;
            .shortVideo-name {
              font-size: 2.2rem;
              width: 16.7rem;
              margin: 0 auto;
            }
            .red {
              position: absolute;
              left: 43%;
              top: 43px;
              width: 10rem;
              height: 0.42rem;
              background-color: #cf0000;
            }
          }
          .shortVideo-left-con {
            height: 260px;
            width: 100%;
            border: 1px solid #ccc;
            .shortVideo-left-con-img {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / cover;
              background-color: #000;
              position: relative;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .rd-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / cover;
                cursor: pointer;
                position: absolute;
                top: 0px;
                left: 0px;
              }
              .video-text {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
                background-color: rgba(0, 0, 0, 0.3);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .shortVideo-right {
          width: 28%;
          height: 100%;
          position: relative;
          .shortVideo-right-con {
            .shortVideo-right-list {
              height: 12.9rem;
              width: 100%;
              position: relative;
              margin-bottom: 2rem;
              background-color: #000;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .rd-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                cursor: pointer;
                position: absolute;
                top: 0px;
                left: 0px;
              }
              .video-text {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
                background-color: rgba(0, 0, 0, 0.3);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .materialVideo {
      height: auto;
      width: 750px;
      margin: 0 auto;
      .materialVideo-list {
        display: flex;
        .material-item {
          width: 176px;
          height: 121px;
          position: relative;
          margin-right: 10px;
          video {
            width: 100%;
            object-fit: cover;
          }
          .mv-img {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
          }
          .mv-text {
            position: absolute;
            bottom: 0px;
            height: 30px;
            width: 100%;
            line-height: 30px;
            color: #fff;
            font-size: 14px;
            background-color: rgba(0, 0, 0, 0.3);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .tuku-con {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 700px;
      overflow: hidden;
      .tuku-list {
        width: 31.9rem;
        height: 28.75rem;
        margin-bottom: 1.7rem;
        display: flex;
        .tuku-list-left {
          width: 10%;
          position: relative;
          .tuku-list-title {
            cursor: pointer;
          }
          div {
            width: 1.7rem;
            font-size: 1.8rem;
            margin-left: 0.85rem;
            writing-mode: vertical-rl;
          }
          span {
            display: inline-block;
            width: 1.7rem;
            position: absolute;
            writing-mode: vertical-rl;
            bottom: 3.5rem;
            right: 0.85rem;
            font-size: 1.3rem;
            cursor: pointer;
          }
        }
        .tuku-list-right {
          width: 90%;
          .tuku-list-rightimg {
            height: 25.4rem;
            background-size: cover;
          }
          .tuku-list-righttext {
            height: 3.33rem;
            line-height: 3.33rem;
            font-size: 1.3rem;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
      .tu-more {
        color: #918d8c;
        position: relative;
        margin-left: 1.7rem;
        .tuku-more-text {
          width: 28.7rem;
          background-color: #dfdbda;
          height: 307px;
          position: absolute;
          left: 38.3px;
          top: 0px;
          .tuku-all {
            position: absolute;
            left: 30%;
            top: 38%;
            height: 2.5rem;
            width: 12.5rem;
            font-size: 2rem;
          }
          .clip {
            position: absolute;
            top: 52%;
            left: 45%;
            display: flex;
            height: 4.2rem;
            width: 10rem;
            div {
              height: 0.85rem;
              width: 0.85rem;
              border-radius: 0.42rem;
              background-color: #918d8c;
              margin-right: 0.85rem;
            }
          }
        }
      }
    }
    .tuku-btn {
      position: absolute;
      bottom: 1.67rem;
      right: -6.67rem;
      width: 5rem;
      height: 5rem;
      border: 2px solid #acacac;
      .tuku-btn-right {
        width: 1.7rem;
        height: 1.7rem;
        border-top: 2px solid #acacac;
        border-right: 2px solid #acacac;
        transform: rotate(45deg);
        position: absolute;
        left: 1.2rem;
        top: 1.5rem;
      }
    }
    .news-btn {
      position: absolute;
      right: -6.67rem;
      bottom: 19.75rem;
      width: 5.5rem;
      height: 8.3rem;
      .news-btn-right {
        width: 3rem;
        height: 3rem;
        border-radius: 3.33rem;
        border: 0.42rem solid #ff8213;
        margin-bottom: 0.85rem;
        position: relative;
        .news_btn_right {
          width: 1rem;
          height: 1rem;
          border-top: 2px solid #ffce9f;
          border-right: 2px solid #ffce9f;
          transform: rotate(45deg);
          position: absolute;
          left: 0.66rem;
          top: 1rem;
        }
      }
      .news-btn-left {
        width: 3rem;
        height: 3rem;
        border-radius: 3.33rem;
        border: 0.42rem solid #f6b887;
        position: relative;
        .news_btn_left {
          width: 1rem;
          height: 1rem;
          border-top: 2px solid #ffce9f;
          border-left: 2px solid #ffce9f;
          transform: rotate(-45deg);
          position: absolute;
          left: 1.2rem;
          top: 1rem;
        }
      }
    }
    .culture-con {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto;
      overflow: hidden;
      .culture-list {
        width: 354px;
        height: 195px;
        .culture-img {
          height: 160px;
          width: 100%;
          background-size: cover;
          cursor: pointer;
        }
        .culture-text {
          font-size: 14px;
          display: flex;
          .icon {
            display: inline-block;
            width: 27px;
            height: 21px;
            margin-left: 131px;
            // background: no-repeat url(../assets/culturetwo.png);
            background-size: cover;
          }
          .culture-t {
            cursor: pointer;
          }
          div {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
    .edu-con {
      display: flex;
      justify-content: space-between;
      border: 0.66rem solid #c7c5c6;
      border-radius: 0.42rem;
      padding: 2.5rem;
      margin-top: 1.7rem;
      .edu-list {
        width: 29.17rem;
        height: 16.7rem;
        div {
          width: 29.17rem;
          height: 16.7rem;
          background-size: cover;
        }
      }
    }
    .zhuanti-con {
      display: flex;
      flex-shrink: 0;
      .zhuanti-left {
        width: 65%;
        height: 39.7rem;
        position: relative;
        overflow: hidden;
        .zhuanti-left-img {
          width: 100%;
          height: 39.7rem;
          position: relative;
          overflow: hidden;
          transition: all 200ms ease-in-out;
          overflow: hidden;
          background-size: cover;
          cursor: pointer;
        }
        .zhunti-con-text {
          position: absolute;
          bottom: 0px;
          height: 4.2rem;
          width: 100%;
          line-height: 4.2rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          text-align: center;
          font-size: 1.3rem;
          transition: all 200ms ease-in-out;
          overflow: hidden;
        }
      }
      .zhuanti-left:hover {
        .zhuanti-left-img {
          transform: scale(1.05);
        }
        .zhunti-con-text {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .zhuanti-right {
        width: 35%;
        background: #000;
        margin-left: 0.85rem;
        .zhuanti-right-top {
          width: 27.8rem;
          height: 86%;
          overflow: hidden;
          .zhuanti-right-img {
            width: 27.8rem;
            height: 16.7rem;
            margin-bottom: 0.85rem;
            background-size: cover;
            cursor: pointer;
            position: relative;
            transition: all 200ms ease-in-out;
            overflow: hidden;
            .zhuanti-right-text {
              position: absolute;
              bottom: 0px;
              height: 3.33rem;
              width: 100%;
              line-height: 3.33rem;
              background-color: rgba(0, 0, 0, 0.3);
              color: #fff;
              text-align: center;
              font-size: 1.3rem;
              transition: all 200ms ease-in-out;
            }
          }
          .zhuanti-right-img:hover {
            transform: scale(1.05);
            .zhuanti-right-text {
              background-color: rgba(0, 0, 0, 0.5);
            }
          }
        }
        .zhuanti-right-bottom {
          height: 4.67rem;
          width: 100%;
          .zhuanti-right-text {
            width: 25rem;
            color: #fff;
            font-size: 2rem;
            margin-left: 0.85rem;
          }
          .zhuanti-right-clip {
            display: flex;
            margin-left: 4.2rem;
            div {
              width: 1rem;
              height: 1rem;
              border-radius: 0.5rem;
              background: #c7c5c6;
              margin-right: 0.85rem;
              margin-left: 1.3rem;
              margin-top: 0.85rem;
            }
          }
        }
      }
    }
  }
  .sky {
    width: 750px;
    margin: 1.7rem auto;
    position: relative;
    .sky-title {
      position: relative;
      height: 80px;
      width: 750px;
      margin: 15px auto;
      line-height: 10rem;
      .sky-name {
        width: 100px;
        margin: 0 auto;
        font-size: 22px;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 43%;
        top: 7.5rem;
        width: 100px;
        height: 4px;
        background-color: #cf0000;
      }
    }
    .sky-con {
      height: 479px;
      position: relative;
      .sky-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }
      .sky-pic.active {
        opacity: 1;
      }
    }
    .sky-text {
      display: flex;
      margin: 25px auto;
      position: absolute;
      bottom: 0px;
      width: 100%;
      justify-content: center;
      .nav-pic {
        height: 12.5px;
        width: 12.5px;
        border-radius: 6.6px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        margin-right: 8px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }
      .nav-pic.active {
        background-color: rgb(23, 47, 184);
        width: 35px;
      }
    }
    .sky-two {
      height: 120px;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      .skytwo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-shrink: 0;
        width: 100%;
        .skytwo-con {
          width: 239px;
          height: 121px;
          position: relative;
          overflow: hidden;
          .skytwo-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .skytwo-text {
            position: absolute;
            bottom: 6px;
            left: 50%;
            transform: translate(-50%);
            width: 85%;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: all 500ms ease-in-out;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .skytwo-con:hover {
          .skytwo-img {
            transform: scale(1.05);
          }
          .skytwo-text {
            background-color: rgba(0, 0, 0, 0.4);
            width: 92%;
          }
        }
      }
    }
    .sky-three {
      height: 255px;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      display: flex;
      .sky-three-left {
        width: 478px;
        height: 100%;
        margin-right: 15px;
        position: relative;
        overflow: hidden;
        .skythree-left-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .skythree-con-text {
          position: absolute;
          bottom: 0px;
          transition: all 500ms ease-in-out;
          width: 100%;
          color: #fff;
          font-size: 14px;
          height: 30px;
          line-height: 30px;
          background-color: rgba(0, 0, 0, 0.3);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .sky-three-left:hover {
        .skythree-left-img {
          transform: scale(1.05);
        }
        .skythree-con-text {
          height: 35px;
          font-size: 15px;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
      .sky-three-right {
        width: 250px;
        height: 100%;
        .skythree-right {
          width: 100%;
          height: 48%;
          margin-bottom: 15px;
          position: relative;
          overflow: hidden;
          .skythree-right-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .skythree-right-text {
            position: absolute;
            bottom: 0px;
            width: 100%;
            color: #fff;
            transition: all 500ms ease-in-out;
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            background-color: rgba(0, 0, 0, 0.3);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .skythree-right:hover {
          .skythree-right-img {
            transform: scale(1.05);
          }
          .skythree-right-text {
            height: 35px;
            font-size: 15px;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
    .ft-threedouble {
      height: 44rem;
      width: 120rem;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .threedouble-con {
        width: 38.5rem;
        height: 20rem;
        position: relative;
        margin-bottom: 2rem;
        .three-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .three-text {
          position: absolute;
          bottom: 0.66rem;
          left: 50%;
          transform: translate(-50%);
          width: 85%;
          border-radius: 0.42rem;
          font-size: 1.3rem;
          text-align: center;
          height: 3.9rem;
          line-height: 3.9rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          transition: all 500ms ease-in-out;
        }
      }
      .threedouble:hover {
        .three-img {
          transform: scale(1.05);
        }
        .three-text {
          background-color: rgba(0, 0, 0, 0.4);
          width: 92%;
        }
      }
    }
    .ft-four {
      height: auto;
      width: 750px;
      margin: 0 auto;
      display: flex;
      .ft-four-left {
        width: 32%;
        .ft-four-left-con {
          height: 121px;
          width: 223px;
          margin-bottom: 12px;
          position: relative;
          overflow: hidden;
          .ft-four-left-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .ft-four-left-text {
            position: absolute;
            bottom: 0.66rem;
            left: 50%;
            transform: translate(-50%);
            width: 85%;
            border-radius: 0.42rem;
            font-size: 14px;
            text-align: center;
            height: 3.9rem;
            line-height: 3.9rem;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: all 500ms ease-in-out;
          }
        }
        .ft-four-left-con:hover {
          .ft-four-left-img {
            transform: scale(1.05);
          }
          .ft-four-left-text {
            background-color: rgba(0, 0, 0, 0.4);
            width: 92%;
          }
        }
      }
      .ft-four-right {
        width: 68%;
        .ft-four-right-con {
          height: 256px;
          position: relative;
          overflow: hidden;
          .ft-four-right-img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .ft-four-right-text {
            position: absolute;
            bottom: 6px;
            left: 50%;
            transform: translate(-50%);
            width: 85%;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: all 500ms ease-in-out;
          }
        }
        .ft-four-right-con:hover {
          .ft-four-right-img {
            transform: scale(1.05);
          }
          .ft-four-right-text {
            background-color: rgba(0, 0, 0, 0.4);
            width: 92%;
          }
        }
      }
    }
    .ft-14 {
      height: 250px;
      width: 750px;
      margin: 0px auto;
      position: relative;
      .item-con {
        position: absolute;
        overflow: hidden;
        .item-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .item-text {
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translate(-50%);
          width: 85%;
          border-radius: 3px;
          font-size: 8px;
          text-align: center;
          height: 24px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          transition: all 500ms ease-in-out;
        }
      }
      .item-con:nth-child(1) {
        left: 0px;
        top: 0px;
        height: 250px;
        width: 375px;
      }
      .item-con:nth-child(2) {
        left: 387.5px;
        top: 0px;
        height: 118.75px;
        width: 175px;
      }
      .item-con:nth-child(3) {
        left: 575px;
        top: 0px;
        height: 118.75px;
        width: 175px;
      }
      .item-con:nth-child(4) {
        left: 387.5px;
        top: 131.25px;
        height: 118.75px;
        width: 175px;
      }
      .item-con:nth-child(5) {
        left: 575px;
        top: 131.25px;
        height: 118.75px;
        width: 175px;
      }
      .item-con:nth-child(n + 6) {
        display: none;
      }
    }

    .ft-4s {
      height: 150px;
      width: 750px;
      margin: 0px auto;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1%;
      .item-con {
        position: relative;
        overflow: hidden;
        height: 100%;
        flex: 1;
        .item-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .item-text {
          position: absolute;
          bottom: 0.66rem;
          left: 50%;
          transform: translate(-50%);
          width: 85%;
          border-radius: 0.42rem;
          font-size: 1.3rem;
          text-align: center;
          height: 3.9rem;
          line-height: 3.9rem;
          background-color: rgba(0, 0, 0, 0.3);
          color: #fff;
          transition: all 500ms ease-in-out;
        }
      }
      .item-con:hover {
        .item-img {
          transform: scale(1.05);
        }
        .item-text {
          background-color: rgba(0, 0, 0, 0.4);
          width: 92%;
        }
      }
      .item-con:nth-child(n + 5) {
        display: none;
      }
    }
  }

  .local {
    width: 750px;
    margin: 15px auto;
    position: relative;
    .local-title {
      position: relative;
      height: 80px;
      width: 750px;
      margin: 17px auto;
      line-height: 10rem;
      .local-name {
        width: 100px;
        margin: 0 auto;
        font-size: 22px;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 43%;
        top: 45px;
        width: 100px;
        height: 4px;
        background-color: #cf0000;
      }
    }
    .local-con {
      .local-list {
        width: 320px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .local-list-name {
          height: auto;
          transition: all 400ms ease-in-out;
          font-size: 14px;
          width: auto;
          padding: 5px 15px;
          border: 1px solid #ccc;
          border-radius: 15px;
          margin-right: 5px;
          margin-top: 5px;
          background-color: #ebedf1;
          cursor: pointer;
        }
        .active {
          background-color: #d42d26;
          color: #fff;
        }
      }
      .local-detail {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-shrink: 0;
        margin-top: 30px;
        overflow: hidden;
        transition: all 400ms ease-in-out;
        .local-detail-list {
          height: 126px;
          width: 173px;
          margin-right: 6px;
          background-color: #ebedf1;
          margin-bottom: 15px;
          position: relative;
          overflow: hidden;
          .local-detail-img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
            cursor: pointer;
            transition: all 300ms ease-in-out;
          }
          .local-detail-text {
            height: 25px;
            width: 100%;
            line-height: 25px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            color: #000;
            cursor: pointer;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            bottom: 0px;
            background-color: rgba(255, 255, 255, 0.4);
            transition: all 400ms ease-in-out;
            padding: 0px 0.42rem;
          }
          .local-detail-picsnumber {
            height: 2.5rem;
            width: 100%;
            line-height: 2.5rem;
            font-size: 1.2rem;
            position: absolute;
            bottom: -8.33rem;
            background-color: rgba(255, 255, 255, 0.4);
            transition: all 600ms ease-in-out;
            left: 50%;
            transform: translate(-50%);
          }
        }
        .local-detail-list:nth-child(1) {
          width: 365px;
          height: 126px;
          margin-right: 5px;
          background-color: #ebedf1;
        }
        .local-detail-list:hover {
          .local-detail-text {
            bottom: 25px;
            border-radius: 5px;
            width: 90%;
            background-color: rgba(255, 255, 255, 0.8);
          }
          .local-detail-picsnumber {
            bottom: 0.42rem;
            width: 90%;
            border-radius: 0.42rem;
            background-color: rgba(255, 255, 255, 0.8);
          }
          .local-detail-img {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .activity {
    width: 750px;
    margin: 15px auto;
    position: relative;
    .activity-title {
      position: relative;
      height: 80px;
      width: 750px;
      margin: 15px auto;
      line-height: 10rem;
      .activity-name {
        width: 100px;
        margin: 0 auto;
        font-size: 22px;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 43%;
        top: 45px;
        width: 100px;
        height: 4px;
        background-color: #cf0000;
      }
    }
    .activity-con {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      width: 100%;
      .activity-list {
        width: 229px;
        height: auto;
        margin-right: 23px;
        display: flex;
        .activity-left {
          width: 122px;
          height: 100%;
          background-size: cover;
          cursor: pointer;
        }
        .activity-right {
          text-align: left;
          padding-left: 8px;
          width: 108px;
          background-color: #fff;
          .ac-right-title {
            color: red;
            font-size: 14px;
            min-height: 30px;
            margin-top: 8px;
          }
          .ac-right-conmany {
            font-size: 14px;
            margin-bottom: 4px;
            min-height: 30px;
          }
          .ac-right-link {
            font-size: 1.5rem;
            margin-bottom: 0.42rem;
            min-height: 3.33rem;
            display: flex;
            .link-one {
              width: 8.3rem;
              height: 2.5rem;
              line-height: 2.5rem;
              border: 0.25rem solid red;
              color: red;
            }
            .link-two {
              width: 5rem;
              height: 3rem;
              background-color: red;
              cursor: pointer;
              .sanjiao {
                border-width: 1rem;
                border-color: transparent transparent transparent #fff;
                border-style: solid;
                margin-left: 1.7rem;
                margin-top: 0.42rem;
              }
            }
          }
          .ac-right-text {
            height: 40px;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .userShow {
    width: 750px;
    margin: 0px auto;
    position: relative;
    .userShow-title {
      position: relative;
      height: 80px;
      line-height: 80px;
      .userShow-name {
        width: 120px;
        margin: 0 auto;
        font-size: 22px;
        cursor: pointer;
      }
      .red {
        position: absolute;
        left: 42%;
        top: 55px;
        width: 120px;
        height: 4px;
        background-color: #cf0000;
      }
    }
    .userShow-con {
      width: 750px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      height: 120px;
      .userShow-swiper {
        display: flex;
        flex-wrap: nowrap;
        flex-shrink: 0;
        width: 116%;
        position: absolute;
        .userShow-list {
          width: 108px;
          height: 108px;
          .userShow-img {
            width: 80px;
            height: 80px;
            border-radius: 40px;
            border: 0.083 solid #ccc;
            background-size: cover;
            margin-left: 20px;
            cursor: pointer;
          }
          .userShow-text {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .transformUp {
          transform: translateX(-108px);
          transition: all 1000ms ease-in-out;
        }
      }
    }
  }

  .ad {
    width: 750px;
    margin: 0 auto;
    .ad-con {
      height: auto;
      width: 100%;
      a {
        width: 100%;
        .ad-detail {
          width: 100%;
        }
      }
    }
  }

  .link {
    width: 750px;
    margin: 0 auto;
    .link-title {
      height: 50px;
      display: flex;
      position: relative;
      margin-left: 50px;
      .link-name {
        width: 140px;
        text-align: left;
        font-size: 22px;
        margin-top: 15px;
      }
      .clip {
        position: absolute;
        left: 93px;
        top: 38px;
        width: 89.25rem;
        height: 0.85rem;
        background: #d9d9d9;
      }
    }
    .link-con {
      overflow: hidden;
      width: 700px;
      margin-left: 50px;
      .link-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .link-detail {
          width: auto;
          height: 38px;
          line-height: 5.5rem;
          margin: 0.42rem 1.25rem 0.42rem 0px;
          padding: 0.42rem 1.25rem 0.42rem 0px;
          a {
            height: 100%;
            width: 100%;
            display: inline-block;
            text-decoration: none;
            color: #000;
            font-size: 1.3rem;
          }
        }
        .transformLeft {
          transform: translateX(-54rem);
          transition: all 800ms ease-in-out;
        }
      }
    }
  }

  .ifame-container {
    height: 29.17rem;
    background-color: #f1f1f1;
    margin-bottom: 1.7rem;
    width: 100rem;
    margin: 0 auto;
    .ifame-detail {
      width: 100rem;
      margin: 0 auto;
    }
  }
}
</style>
