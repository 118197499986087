<template>
  <div class="whole">
    <div class="title">
      <div class="log-img"></div>
      <div class="name">新闻摄影学会县市传媒分会</div>
      <div class="search">
        <el-input></el-input>
      </div>
    </div>
    <div class="nav">
      <div class="nav-list" v-if="navList">
        <div class="nav-detail" v-for="(k, navIndex) in navList" :key="k + 'nav_' + navIndex" :class="{ active: clickIndex == navIndex }">
          <a :href="k.url" target="_blank">{{ k.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  data() {
    return {
      navList: [
        { name: '首页', url: '/fixTopic.html?id=4' },
        {
          name: '学会简介',
          url: '/videoCatalog.html?title=视频',
        },
        {
          name: '业内焦点',
          url: '/userShow.html',
        },
        {
          name: '年度评选',
          url: '/activity.html',
        },
        {
          name: '融媒任务',
          url: '/memberIndex.html',
        },
        {
          name: '优秀版面',
          url: '/',
        },
        {
          name: '短视频',
          url: '/',
        },
        {
          name: '年度论文',
          url: '/',
        },
        {
          name: '航拍中国',
          url: '/',
        },
        {
          name: '下载',
          url: '/',
        },
      ],
      clickIndex: '', // nav 的当前点击
    }
  },
  created() {
    var thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        thiz.$rtm.store.userInfo = data
        thiz.$rtm.store.logined = true
        if (data.approveStatusName == '未通过') {
          thiz.noUpload = false
        } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
          thiz.noUpload = true
        }
        thiz.reload()
      })
      .catch(() => {})
    this.reload()
  },
  methods: {
    reload() {},
  },
}
</script>

<style lang="less" scoped>
.whole {
  height: 12rem;
  background-color: rgba(252, 195, 40, 0.5);
  .title {
    width: 120rem;
    margin: 0 auto;
    height: 50%;
    display: flex;
    position: relative;
    .log-img {
      height: 80%;
      width: 20%;
      background: no-repeat url(../assets/logo.png);
      background-size: contain;
      margin-top: 0.6rem;
    }
    .name {
      width: 58%;
      font-size: 4rem;
      letter-spacing: 8px;
      margin-top: 0.5rem;
    }
    .search {
      position: absolute;
      right: 0px;
      top: 1rem;
    }
  }
  .nav {
    width: 120rem;
    margin: 0 auto;
    height: 50%;
    .nav-list {
      display: flex;
      width: 100%;
      height: 6rem;
      line-height: 6rem;
      line-height: 6rem;
      flex-shrink: 0;
      flex-wrap: wrap;
      .nav-detail {
        font-size: 1.8rem;
        cursor: pointer;
        text-align: left;
        height: 6rem;
        width: 10rem;
        a {
          display: inline-block;
          width: 100%;
          height: 50%;
          color: #000;
          text-decoration: none;
          padding: 0px 0.8rem;
        }
      }
      .nav-detail:hover {
        color: #fff;
        background: #ff8c00;
        a {
          color: #fff;
        }
        .nav-detail-subs {
          display: block;
        }
        .nav-detail-seq {
          height: 2.5rem;
          line-height: 2.5rem;
          margin: 0.8rem 0px 0px 0px;
          a {
            height: 2.5rem;
            color: #fff;
          }
        }
      }
      .nav-detail.active {
        color: red;
      }
    }
  }
}
</style>